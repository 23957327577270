<template>
  <div>
    <div class="list-links grid-links">
      <a
        class="item"
        :href="item.url"
        target="_blank"
        v-for="(item, i) in items"
        :key="i"
      >
        <img
          :src="item.ico"
          alt=""
        />
        <div class="tl">{{ item.title }}</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          ico: "https://f.start.me/mail.google.com",
          title: "Gmail",
          url: "https://mail.google.com/",
        },
        {
          ico: "https://f.start.me/google.com",
          title: "Google",
          url: "https://www.google.com/",
        },
        {
          ico: "https://f.start.me/youtube.com",
          title: "Youtube",
          url: "https://www.youtube.com/",
        },
        {
          ico: "https://f.start.me/linkedin.com",
          title: "Linkedin",
          url: "https://www.linkedin.com/",
        },
        {
          ico: "https://f.start.me/bing.start.me",
          title: "Bing",
          url: "https://www.bing.com/?",
        },
        {
          ico: "https://f.start.me/facebook.com",
          title: "Facebook",
          url: "https://www.facebook.com/",
        },
      ],
    };
  },
};
</script>


<style lang="scss">
.grid-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  .item {
    max-width: 25%;
    min-width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 52px;
      height: 52px;
      object-fit: contain;
      margin-bottom: 8px;
    }
  }
}
</style>